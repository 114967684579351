export interface AppEnvironment {
  production: boolean;
  name: string;
  default: string;
  unlayer_project_key: number;
  dws_base_url: string;
  features?: {
    [name: string]: boolean
  };
  hosts: {
    [name: string]: AppHostConfig
  };
  values: any;
}

export interface AppHostConfig {
  host?: string;
  protocol?: string;
  port?: string;
  root?: string;
  unauthenticated?: boolean;
  local?: boolean;
  endpoints: {
    [name: string]: string;
  };
}

export const environment: AppEnvironment = {
  production: false,
  name: 'common',
  default: 'v2',
  unlayer_project_key: 223004,
  dws_base_url: 'https://dws-staging.divinea.com',
  features: {
    'campaign-test-email': true,
    'reservation-boundless-datetime': true,
    'automations': true,
    'sendgrid-statistics': true,
    'campaign-active': false,
    'company-management-privacy-files': true,
    'unsubscribe-campaign': true,
    'save-template': true,
    'company-management-payouts': true,
    'days-no-purchase-automation': true,
    'conversion-statistic': true,
    'subscribe-link': true,
    'subscription-conditions': false,
    'subscription-invitation-automation': true,
    'order-send-receipt-email': true,
    'external-id-channel': true,
    'product-channel-categories': true,
    'winery-channel-name': true,
    'archive-contact': true,
    'instant-booking': true,
    'delete-channels': false,
    'winery-transactional-emails': true,
    'paypal-split-payment': false,
    'google-calendar-integration': false,
    'winelivery': true,
    'automatic-approve': true,
    'wineplatform': true,
    'spotty': true,
    'filters-widget': true,
    'country-in-reservation': true,
    'category-product': true,
    'availability-data-pdf': true,
    'close-matching-slot-languages-in-the-same-experience': true,
    'automatically-accept-reservations-on-same-slot': true
  },
  values: {
    'storage-key': 'winery-saas',
    'fcm-worker': 'assets/js/firebase-messaging-sw-staging.js',
    'manual-url': 'https://saas-manual.s3-eu-west-1.amazonaws.com/index.html',
    cache: {
      enabled: false,
      'max-age': 10 * 60 * 1000
    },
    firebaseConfig: {},
    dateSendGridStatistics: '2021-03-30',
    'google-client-id': '209822336933-0kalbgqjn56g0aol2jnss4v86h9r86jg.apps.googleusercontent.com',
    'outlook-client-id': '259abbff-6218-4b25-9434-b1fcb4add280',
    'outlook-redirect-uri': 'http://localhost:4200',
    'preorder-url': 'https://preorders-staging.divinea.com',
    'stripe-dashboard-url': 'https://dashboard.stripe.com/test',
  },
  hosts: {
    crm: {
      root: '/api/',
      endpoints: {
        'availability-block': 'availability-block',
        'availability-block/id': 'availability-block/{id}',
        'person-contacts': 'person-contacts',
        'person-contacts/id': 'person-contacts/{id}',
        'company-contacts': 'company-contacts',
        'company-contacts/id': 'company-contacts/{id}',
        'company-person-contacts': 'company-person-contacts',
        'company-person-contacts/companyContactId/personContactId': 'company-person-contacts/{companyContactId}/{personContactId}',
        'contacts-wineries': 'contacts-wineries',
        'contacts-wineries/contactId/wineryId': 'contacts-wineries/{contactId}/{wineryId}',
        'contacts-wineries/contactId/wineryId/unsubscribe': 'contacts-wineries/{contactId}/{wineryId}/unsubscribe',
        'contacts-wineries/contactId/wineryId/archive': 'contacts-wineries/{contactId}/{wineryId}/archive',
        'contacts-wineries/contactId/wineryId/unarchive': 'contacts-wineries/{contactId}/{wineryId}/unarchive',
        'emails': 'emails',
        'emails/id': 'emails/{id}',
        'reservations': 'reservations',
        'sale-reservations/page': 'sale-reservations/page',
        'reservations/id': 'reservations/{id}',
        'emails/email': 'emails/{email}',
        'emails/emailWithWinery': 'emails/emailWithWinery',
        'phones': 'phones',
        'phones/id': 'phones/{id}',
        'phones/phone': 'phones/phone/{phone}',
        'languages': 'languages',
        'contacts-languages': 'contacts-languages',
        'contacts-languages/contactId/languageId': 'contacts-languages/{contactId}/{languageId}',
        'contacts': 'contacts',
        'contacts/filter-by': 'contacts/filter-by',
        'contacts/matchers': 'contacts/matchers',
        'contacts/id': 'contacts/{id}',
        'contacts/v2/id': 'contacts/v2/{id}',
        'contacts/id/companyId': 'contacts/{id}/{companyId}',
        'contacts/email': 'v2/contacts/email',
        'contacts/update-short/id': 'v2/contacts/update-short/{id}',
        'contacts/v2/id/companyId': 'contacts/v2/{id}/{companyId}',
        'contacts/ref': 'contacts/ref/{ref}',
        'contacts-tags': 'contacts-tags',
        'contacts-tags/contactId/tagId': 'contacts-tags/{contactId}/{tagId}',
        'contacts-origin/wineryId': 'contacts-origin/{wineryId}',
        'events': 'events',
        'events/id': 'events/{id}',
        'visit-frequencies': 'visit-frequencies',
        'origins': 'origins',
        'wine-preferences': 'wine-preferences',
        'customer-profiles': 'customer-profiles',
        'addresses': 'addresses',
        'addresses/id': 'addresses/{id}',
        'addresses/cities': 'addresses/cities',
        'addresses/provinces': 'addresses/provinces',
        'countries': 'countries',
        'countries/id': 'countries/{id}',
        'reservations/statistics': 'reservations/statistics',
        'tags': 'tags',
        'tags/id': 'tags/{id}',
        'segments': 'segments',
        'segments/id': 'segments/{id}',
        'tags/winery/wineryId': 'tags/winery/{wineryId}',
        'contact-matchers': 'contact-matchers',
        'orders': 'orders',
        'orders/id': 'orders/{id}',
        'orders/export': 'orders/export',
        'orders/orders-countries': 'orders/orders-countries',
        'orders/orders-statuses': 'orders/orders-statuses',
        'orders/id/send-receipt-email': 'orders/{id}/send-receipt-email',
        'statistics': 'statistics',
        'statistics/orders': 'statistics/orders',
        'statistics/orders/products': 'statistics/orders/products',
        'statistics/campaigns': 'statistics/campaigns',
        'statistics/campaigns/multiple': 'statistics/campaigns/multiple',
        'statistics/campaigns/id': 'statistics/campaigns/{id}',
        'statistics/campaigns/id/summary': 'statistics/campaigns/{id}/summary',
        'statistics/campaigns/id/summarybydate': 'statistics/campaigns/{id}/summarybydate',
        'product-types': 'product-types',
        'products': 'products',
        'products/checkname': 'products/checkname',
        'products/id': 'products/{id}',
        'products/id/active-toggle': 'products/{id}/active-toggle',
        'products/grid/wineryId': 'products/grid/{wineryId}',
        'products/id/sort-product': 'products/{id}/sort-product',
        'products/id/sort-product-by-category': 'products/{id}/sort-product-by-category',
        'files': 'files',
        'files/id': 'files/{id}',
        'product-files': 'product-files',
        'products/id/orders': 'products/{id}/orders',
        'product-channels': 'product-channels',
        'product-channels/id': 'product-channels/{id}',
        'statistics/products': 'statistics/products',
        'winery/wineryId/category': 'winery/{wineryId}/category',
        'winery/wineryId/closing-days': 'winery/{wineryId}/closing-days',
        'winery/wineryId/category/id': 'winery/{wineryId}/category/{id}',
        'winery/wineryId/category/id/priority': 'winery/{wineryId}/category/{id}/priority',
        'winery-channels': 'winery-channels',
        'winery-channels/id': 'winery-channels/{id}',
        'winery-channels/id/categories': 'winery-channels/{id}/categories',
        'winery-channels/id/products': 'winery-channels/{id}/products',
        'order-payment-types': 'order-payment-types',
        'order-status': 'order-status',
        'coupons': 'coupons',
        'coupons/id': 'coupons/{id}',
        'audiences': 'audiences',
        'audiences/id': 'audiences/{id}',
        'audiences/{audienceId}/contacts': 'audiences/{audienceId}/contacts',
        'audiences/winery/wineryId': 'audiences/winery/{wineryId}',
        'audience-contacts': 'audience-contacts',
        'campaigns': 'campaigns',
        'campaigns/id': 'campaigns/{campaignId}',
        'campaigns/id/clone': 'campaigns/{campaignId}/clone',
        'campaigns/id/clone-with-contacts': 'campaigns/{campaignId}/clone-with-contacts',
        'campaigns/id/activate': 'campaigns/{campaignId}/activate',
        'campaigns/id/deactivate': 'campaigns/{campaignId}/deactivate',
        'campaigns/test-email': 'campaigns/test-email',
        'campaign-templates': 'campaign-templates',
        'campaign-templates/id': 'campaign-templates/{campaignTemplateId}',
        'campaign-targets': 'campaign-targets',
        'campaign-targets/id': 'campaign-targets/{campaignTargetId}',
        'campaign-targets/campaign/campaignId': 'campaign-targets/campaign/{campaignId}',
        'campaign-origins': 'campaign-origins',
        'campaign-origins/campaign/campaignId': 'campaign-origins/campaign/{campaignId}',
        'campaign-contacts': 'campaign-contacts',
        'campaign-contacts/for-selection': 'campaign-contacts/for-selection',
        'campaign-contacts/id': 'campaign-contacts/{campaignId}',
        'campaign-contacts/campaign/campaignId': 'campaign-contacts/campaign/{campaignId}',
        'campaign-target-wineries/campaignId': 'campaign-target-wineries/{campaignId}',
        'campaigns/id/contacts': 'campaigns/{campaignId}/contacts',
        'contact-segments': 'contact-segments',
        'contact-segments/contactId/segmentId': 'contact-segments/{contactId}/{segmentId}',
        'automation-types': 'automation-types',
        'automation-types/id': 'automation-types/{id}',
        'track': 'track/{code}',
        'wineries': 'wineries',
        'admin/jobs/contact': 'admin/jobs/contact',
        'admin/jobs/contact/import': 'admin/jobs/contact/import',
        'admin/transactional-emails': 'admin/transactional-emails',
        'admin/transactional-emails/type': 'admin/transactional-emails/{type}',
        'admin/transactional-emails/draft/type': 'admin/transactional-emails/draft/{type}',
        'admin/transactional-emails/clone/type': 'admin/transactional-emails/clone/{type}',
        'admin/transactional-emails/id': 'admin/transactional-emails/{id}',
        'admin/transactional-emails/draft/id': 'admin/transactional-emails/draft/{id}',
        'admin/transactional-emails/id/clone': 'admin/transactional-emails/clone/{id}',
        'admin/transactional-emails/restore/id': 'admin/transactional-emails/restore/{id}',
        'admin/transactional-emails/send-test': 'admin/transactional-emails/send-test',
        'admin/ws-parameters': 'admin/ws-parameters',
        'admin/notifications/push': 'admin/notifications/push',
        'login': 'login',
        'transactional-emails': 'transactional-emails',
        'transactional-emails/type': 'transactional-emails/{type}',
        'transactional-emails/draft/type': 'transactional-emails/draft/{type}',
        'transactional-emails/clone/type': 'transactional-emails/clone/{type}',
        'transactional-emails/id': 'transactional-emails/{id}',
        'transactional-emails/draft/id': 'transactional-emails/draft/{id}',
        'transactional-emails/clone/id': 'transactional-emails/clone/{id}',
        'transactional-emails/delete/id': 'transactional-emails/delete/{id}',
        'transactional-emails/restore/id': 'transactional-emails/restore/{id}',
        'transactional-emails/send-test': 'transactional-emails/send-test',
        'transactional-email-experiences': 'transactional-email-experiences',
        'template-html': 'template-html',
        'admin/template-html':'admin/template-html',
        'admin/template-html/type':'admin/template-html/{type}',
        'admin/template-html/type/draft':'admin/template-html/{type}/draft',
        'admin/template-html/get-pdf':'admin/template-html/get-pdf',
        'template-html/type': 'template-html/{type}',
        'template-html/type/draft': 'template-html/{type}/draft',
        'template-html/get-pdf': 'template-html/get-pdf',
        'inactive-transactional-email/wineryId': 'inactive-transactional-email/{wineryId}',
        'inactive-transactional-email/wineryId/id': 'inactive-transactional-email/{wineryId}/{id}',
        'contact-privacy-history/contactId/wineryId': 'contact-privacy-history/{contactId}/{wineryId}',
        'channels': 'channels',
        'appointments': 'appointments',
        'appointments/id': 'appointments/{id}',
        'google-auth-tokens': 'google-auth-tokens',
        'google-auth-tokens/id': 'google-auth-tokens/{id}',
        'outlook-auth-tokens': 'outlook-auth-tokens',
        'outlook-auth-tokens/id': 'outlook-auth-tokens/{id}',
        'winery-scheduling': 'winery-scheduling',
        'winery-scheduling/experiences-title': 'winery-scheduling/experiences-title',
        'winery-scheduling/origins': 'winery-scheduling/origins',
        'scheduling/page': 'scheduling/page',
        'scheduling-old/date-range': 'scheduling-old/date-range',
        'scheduling/date-range': 'scheduling/date-range',
        'scheduling/check-requests-ids': 'scheduling/check-requests-ids',
        'scheduling/advanced-filters': 'scheduling/advanced-filters',
        'scheduling/get-partecipants-details': 'scheduling/get-partecipants-details/{reservationId}',
        'reservation-export': 'reservation-export',
        'jobs/orders': 'jobs/orders',
        'jobs/orders/id': 'jobs/orders/{id}',
        'jobs/products': 'jobs/products',
        'jobs/products/id': 'jobs/products/{id}',
        'base_template.xlsx': 'base_template.xlsx',
        'product_1_base_template.xlsx': 'product_1_base_template.xlsx',
        'api-order-register.pdf': 'api-order-register.pdf',
        'jobs/contacts': 'jobs/contacts',
        'jobs/contacts/id': 'jobs/contacts/{id}',
        'CRM_importing_contacts_company.xlsx': 'CRM_importing_contacts_company.xlsx',
        'CRM_importing_contacts_person.xlsx': 'CRM_importing_contacts_person.xlsx',
        'gifts': 'gifts',
        'gifts/id': 'gifts/{id}',
        'gifts/experiences-title': 'gifts/experiences-title',
        'gifts/resend': 'gifts/resend',
        'gifts/export': 'gifts/export',
        'gifts/create-voucher':'gifts/create-voucher',
        'contacts/export': 'contacts/export',
        'contact-winery-presence': 'contact-winery-presence',
        'employees': 'employees',
        'employees/id': 'employees/{id}',
        'rooms': 'rooms',
        'rooms/id': 'rooms/{id}',
        'manual-reservations': 'scheduling/manual-reservations',
        'manual-reservations/id': 'scheduling/manual-reservations/{id}',
        'scheduling/reservation-form-options': 'scheduling/reservations/form-options',
        'scheduling/reservation-contacts/id': 'scheduling/reservation-contacts/{id}',
        'scheduling/reservation-contacts': 'scheduling/reservation-contacts',
        'experiences/short-description': 'experiences/short-description',
        'experiences/availabilities': 'experiences/availabilities',
        'admin/experiences/availabilities-with-fill': 'admin/experiences/availabilities-with-fill',
        'experiences/experienceId/availabilities': 'experiences/{experienceId}/availabilities',
        'experiences/experienceId/availabilities/dayOfWeek-time': 'experiences/{experienceId}/availabilities/{dayOfWeek}-{time}',
        'experiences/experienceId/availabilities/override': 'experiences/{experienceId}/availabilities/override',
        //'experiences/experienceId/availabilities/override-count': 'experiences/{experienceId}/availabilities/override-count',
        'admin/experiences/experienceId/availabilities/override-count': 'admin/experiences/{experienceId}/override-count',
        'experiences/experienceId/availabilities/date-time/exists': 'experiences/{experienceId}/availabilities/{date}-{time}/exists',
        'experiences/experienceId/availability-summary/date-time': 'experiences/{experienceId}/availability-summary/{date}-{time}',
        'experiences/export': 'experiences/export',
        'experiences/import': 'experiences/import',
        'experiences/active': 'experiences/active',
        'experiences/sync': 'experiences/sync',
        'availabilities/admin/sync/id': 'availabilities/admin/sync/{id}',
        'experiences/id': 'experiences/{id}',
        'experiences/list': 'experiences/list',
        'v2/reservations/id': 'v2/reservations/{id}',
        'v2/reservations/id/reject': 'v2/reservations/{id}/reject',
        'v2/reservations/id/revoke': 'v2/reservations/{id}/revoke',
        'v2/reservations/id/confirm': 'v2/reservations/{id}/confirm',
        'v2/reservations/id/cancel': 'v2/reservations/{id}/cancel',
        'experiences/event-availabilities': 'experiences/event-availabilities',
        'experiences/experienceId/event-availabilities': 'experiences/{experienceId}/event-availabilities',
        'experiences/experienceId/event-availabilities/day-time': 'experiences/{experienceId}/event-availabilities/{day}-{time}',
        'experiences/experienceId/single-slot-availabilities': 'experiences/{experienceId}/single-slot-availabilities',
        'experiences/experienceId/single-slot-availabilities/day-time': 'experiences/{experienceId}/single-slot-availabilities/{day}-{time}',
        'experiences/experienceId/event-availabilities/override': 'experiences/{experienceId}/event-availabilities/override',
        'admin/experiences/experienceId/event-availabilities/override-count': 'admin/experiences/{experienceId}/override-count/single',
        'experiences/experienceId/single-slot-availabilities/override': 'experiences/{experienceId}/single-slot-availabilities/override',
        //'experiences/experienceId/single-slot-availabilities/override-count': 'experiences/{experienceId}/single-slot-availabilities/override-count',
        'admin/experiences/experienceId/single-slot-availabilities/override-count': 'admin/experiences/{experienceId}/override-count/single',
        'experiences/experienceId/enabled-slot-automations': 'experiences/{experienceId}/enabled-slot-automations',
        'integrations/winelivery/winery/wineryId': 'integrations/winelivery/winery/{wineryId}',
        'integrations/winelivery/internal/experience/experienceId': 'integrations/winelivery/internal/experience/{experienceId}',
        'integrations/winelivery/internal/experience/experienceId/connect': 'integrations/winelivery/internal/experience/{experienceId}/connect',
        'integrations/winelivery/internal/experience/experienceId/disconnect': 'integrations/winelivery/internal/experience/{experienceId}/disconnect',
        'integrations/winelivery/experiences/wineryId': 'integrations/winelivery/experiences/{wineryId}',
        'scheduling/integration-reservation/winelivery/id': 'scheduling/integration-reservation/winelivery/{id}',
        'preorders': 'preorders',
        'preorders/id': 'preorders/{id}',
        'preorders/id/accept': 'preorders/{id}/accept',
        'preorders/id/reject': 'preorders/{id}/reject',
        'account/authenticate': 'account/authenticate',
        'admin/authorize': 'admin/authorize',
        'admin/authorize-as-winery': 'admin/authorize-as-winery',
        'self/account/edit': 'self/account/edit',
        'self/account': 'self/account',
        'account/reset-password/init': 'account/reset-password/init',
        'account/reset-password/check/{email}': 'account/reset-password/check/{email}',
        'account/reset-password/finish': 'account/reset-password/finish',
        'winery/wineryId': 'winery/{wineryId}',
        'winery/wineryId/infowinery': 'winery/{wineryId}/infowinery',
        'winery/wineryId/payment-method': 'winery/{wineryId}/payment-method',
        'winery/wineryId/wineryChannel': 'winery/{wineryId}/wineryChannel',
        'winery/wineryId/wineryChannel/id': 'winery/{wineryId}/wineryChannel/{id}',
        'winery/wineryId/attachment/type': 'winery/{wineryId}/attachment/{type}',
        'winery/wineryId/service': 'winery/{wineryId}/service',
        'winery/wineryId/service/id': 'winery/{wineryId}/service/{id}',
        'winery/wineryId/closingdays': 'winery/{wineryId}/closingdays',
        'winery/wineryId/closingdays/id': 'winery/{wineryId}/closingdays/{id}',
        'winery/wineryId/notification-email/add': 'winery/{wineryId}/notification-email/add',
        'winery/wineryId/notification-email/remove': 'winery/{wineryId}/notification-email/remove',
        'winery/wineryId/parameters': 'winery/{wineryId}/parameters',
        'winery/wineryId/parameters/key': 'winery/{wineryId}/parameters/{key}',
        'winery/wineryId/parameters/gift-duration': 'winery/{wineryId}/parameters/gift-duration',
        'winery/wineryId/qr-codes': 'winery/{wineryId}/qr-codes',
        'winery/wineryId/qr-codes/reduced': 'winery/{wineryId}/qr-codes/reduced',
        'winery/wineryId/qr-codes/id': 'winery/{wineryId}/qr-codes/{id}',
        'winery/wineryId/qr-codes/id/enable': 'winery/{wineryId}/qr-codes/{id}/enable',
        'winery/wineryId/qr-codes/id/disable': 'winery/{wineryId}/qr-codes/{id}/disable',
        'winery/wineryId/calendar-configuration': 'winery/{wineryId}/calendar-configuration',
        'winery/wineryId/wineries': 'winery/{wineryId}/wineries',
        'wifi-providers/login': 'wifi-providers/login',
        'wifi-providers': 'wifi-providers',
        'wifi-providers/id': 'wifi-providers/{id}',
        'winery/wineryId/options/filters-widget': 'winery/{wineryId}/options/filters-widget',
        'ws-parameters': 'ws-parameters',
        'report-dashboard-reservation/vital-stats': 'report-dashboard-reservation/vital-stats',
        'report-dashboard-reservation/time-stats': 'report-dashboard-reservation/time-stats',
        'report-dashboard-reservation/geo-stats': 'report-dashboard-reservation/geo-stats',
        'report-dashboard-reservation/origin-stats': 'report-dashboard-reservation/origin-stats',
        'report-dashboard-reservation/topexp-stats': 'report-dashboard-reservation/topexp-stats',
        'report-dashboard-gifts/vital-stats': 'report-dashboard-gifts/vital-stats',
        'report-dashboard-gifts/time-stats': 'report-dashboard-gifts/time-stats',
        'report-dashboard-gifts/geo-stats': 'report-dashboard-gifts/geo-stats',
        'report-dashboard-gifts/origin-stats': 'report-dashboard-gifts/origin-stats',
        'report-dashboard-gifts/topexp-stats': 'report-dashboard-gifts/topexp-stats',
        'report-dashboard-orders/vital-stats': 'report-dashboard-orders/vital-stats',
        'report-dashboard-orders/time-stats': 'report-dashboard-orders/time-stats',
        'report-dashboard-orders/geo-stats': 'report-dashboard-orders/geo-stats',
        'report-dashboard-orders/origin-stats': 'report-dashboard-orders/origin-stats',
        'report-dashboard-orders/topprod-stats': 'report-dashboard-orders/topprod-stats',
        'report-dashboard-contacts/vital-stats': 'report-dashboard-contacts/vital-stats',
        'report-dashboard-contacts/time-stats': 'report-dashboard-contacts/time-stats',
        'report-dashboard-contacts/geo-stats': 'report-dashboard-contacts/geo-stats',
        'report-dashboard-contacts/origin-stats': 'report-dashboard-contacts/origin-stats',
        'report-dashboard-contact/growth-stats' : 'report-dashboard-contacts/growth-stats',
        'report-dashboard-contacts/toptags-stats': 'report-dashboard-contacts/toptags-stats',
        'report-dashboard-contacts/topsegments-stats': 'report-dashboard-contacts/topsegments-stats',
        'report-dashboard-contacts/topcustomers-stats': 'report-dashboard-contacts/topcustomers-stats',
        'report-dashboard-marketing/vital-stats': 'report-dashboard-marketing/vital-stats',
        'report-dashboard-marketing/income-stats': 'report-dashboard-marketing/income-stats',
        'report-dashboard-marketing/time-stats': 'report-dashboard-marketing/time-stats',
        'report-dashboard-marketing/performance-time-stats': 'report-dashboard-marketing/performance-time-stats',
        'report-dashboard-marketing/conversion-stats': 'report-dashboard-marketing/conversion-stats',
        'report-dashboard-marketing/top-clicks-stats': 'report-dashboard-marketing/top-clicks-stats',
        'report-dashboard-marketing/top-opens-stats': 'report-dashboard-marketing/top-opens-stats',
        'report-dashboard-marketing/top-conversions-stats': 'report-dashboard-marketing/top-conversions-stats',
        'reservations/multiple': 'v2/reservations/multiple',
        'reservations/query': 'v2/reservations/query',
        'contact-emails/emailId': 'contact-emails/{id}',
        'contact-emails/resend/emailId': 'contact-emails/resend/{id}',
        'scheduling/reservation-history': 'scheduling/reservation-history',
        'forms': 'forms',
        'forms/id': 'forms/{formId}',
        'forms/id/activate': 'forms/{formId}/activate',
        'forms/id/deactivate': 'forms/{formId}/deactivate',
        'forms/id/clone': 'forms/{formId}/clone',
        'forms/id/draft': 'forms/{formId}/draft',
        'forms/id/answers': 'forms/{formId}/answers',
        'forms/id/answers/id': 'forms/{formId}/answers/{answerId}',
        'forms/id/urls': 'forms/{formId}/urls',
        'winery-origins': 'winery-origins',
        'winery-origins/urls': 'winery-origins/urls',
        'campaigns/id/archive': 'campaigns/{campaignId}/archive',
        'campaigns/id/unarchive': 'campaigns/{campaignId}/unarchive',
        'campaigns/id/retry': 'campaigns/{campaignId}/retry',
        'external-calendar/{id}/sync': 'external-calendar/{id}/sync',
        'wspayments/sales': 'wspayments/sales',
        'wspayments/sales/id/payment-links': 'wspayments/sales/{id}/payment-links',
        'wspayments/payment-links': 'wspayments/payment-links',
        'wspayments/payment-links/id/send': 'wspayments/payment-links/{id}/send',
        'experiences/generate/details': 'experiences/generate/details',
        'experiences/generate/image': 'experiences/generate/image',
        'forecasts/reservations': 'forecasts/reservations',
        'forecasts/orders': 'forecasts/orders',
      }
    },
    v2: {
      root: '/api/v2/',
      endpoints: {
        'tokens': 'tokens',
        'notification-tokens': 'notification-tokens',
        'password': 'password',
        'host': 'host',
        'host/reservations': 'host/reservations',
        'host/company': 'host/company',
        'host/company/self': 'host/company/self',
        'host/company/reduced': 'host/company/reduced',
        'host/company/contract': 'host/company/contract',
        'host/company/dws-pro': 'host/company/dws-pro',
        'host/company/stripe-connect-link': 'host/company/stripe-connect-link',
        'host/company/paypal-partner-referral-link': 'host/company/paypal-partner-referral-link',
        'host/reservations/id': 'host/reservations/{id}',
        'host/gifts/id/reservations': 'host/gifts/{id}/reservations',
        'host/reservations/export-csv': 'host/reservations/export-csv',
        'host/appointments': 'host/appointments',
        'host/appointments/id': 'host/appointments/{id}',
        'host/experiences': 'host/experiences',
        'host/experiences/id': 'host/experiences/{id}',
        'host/experiences/id/activate': 'host/experiences/{id}/activate',
        'host/experiences/id/deactivate': 'host/experiences/{id}/deactivate',
        'host/experiences/id/availability-override': 'host/experiences/{id}/availability-override',
        'host/experiences-name': 'host/experiences-name',
        'host/clients': 'host/clients',
        'host/products': 'host/products',
        'host/google-authorization': 'host/google-authorization',
        'host/products/id': 'host/products/{id}',
        'host/push-notifications': 'host/push-notifications',
        'host/push-notifications/mex': 'host/push-notifications/mex',
        'host/push-notifications/mark-as-viewed': 'host/push-notifications/mark-as-viewed',
        'host/push-notifications/mark-as-viewed-single': 'host/push-notifications/mark-as-viewed-single',
        'host/push-notifications/id': 'host/push-notifications/{id}',
        'host/emails/id': 'host/emails/{id}',
        'host/emails/next-scheduled': 'host/emails/next-scheduled',
        'host/emails/id/resend': 'host/emails/{id}/resend',
        'host/coupons': 'host/coupons',
        'host/coupons/id': 'host/coupons/{id}',
        'host/rooms': 'host/rooms',
        'host/employees': 'host/employees',
        'host/reservation-form-options': 'host/reservation-form-options',
        'host/reservations/detail/id': 'host/reservations/detail/{id}',
        'host/connect-paypal': 'host/connect-paypal',
        'host/disconnect-payment': 'host/disconnect-payment',
        'host/products-quantity-activated': 'host/products-quantity-activated',
        'host/products/id/change-priority': 'host/products/{id}/change-priority',
        'host/experiences-quantity-activated': 'host/experiences-quantity-activated',
        'host/experiences/id/change-priority': 'host/experiences/{id}/change-priority',
        'experiences/id/availabilities': 'experiences/{id}/availabilities',
        'experiences/id': 'experiences/{id}',
        'experiences': 'experiences',
        'registrations': 'registrations',
        'coupons': 'coupons',
        'host/coupons-valid': 'host/coupons-valid',
        'companies/id': 'companies?ids={id}',
        'manual-origins': 'manual-origins',
        'manual-payment-methods': 'manual-payment-methods',
        'payment-page': 'payment-page',
      }
    },
    v1: {
      root: '/api/v1',
      endpoints: {
        'host': 'host',
        'host/reservations': 'host/reservations',
        'host/reservations/id/confirm': 'host/reservations/{id}/confirm',
        'host/reservations/id/reject': 'host/reservations/{id}/reject',
        'reservations': 'reservations',
        'tag-categories': 'tag_categories',
        'gifts/id/update': 'gifts/{giftId}/update',
        'gifts/id/send_manual_email': 'gifts/{giftId}/send_manual_email',
      }
    },
    divineaCom: {
      root: '/',
      endpoints: {
        'countries/v2/all': 'countries/v2/all',
      }
    }
  }
};